import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import "../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";
// console.log("Environment:", process.env.NODE_ENV);

if (process.env.NODE_ENV !== "development" && window.location.hostname !== "localhost") {
  console.log = function () {}; 
  console.warn = function () {}; 
  console.error = function () {}; 
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ChakraProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
