import { whatsappMsgDeliveryStatus } from "../../../Http/Index";

export const getDeliveryStatus = async (waId: string) => {
  try {
    const response = await whatsappMsgDeliveryStatus(waId);
    return response?.data?.data;
  } catch (err) {
    throw err;
  }
}
